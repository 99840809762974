define(['app', 'accessibleModalHelper', 'siteObj'], (app, accessibleModalHelper, siteObj) => {
  const ProductPersonalisationModal = () => {

    const component = {};

    const _classes = {
      showModal: 'productPersonalisationModal-show',
    };

    const _selectors = {
      closeModalBtn: '[data-close]',
      nextBtn: '[data-next-btn]',
      previousBtn: '[data-previous-btn]',
      resetBtn: '[data-reset-btn]',
      progressBar: '[data-progress-index]',
      stepContent: '[data-step-index]',
      designStep: '[data-remove-design-step]',
      messagePreviewText: '[data-js-element="product-personalisation-modal-message-preview"]',
      namePreviewText: '[data-js-element="product-personalisation-modal-name-preview"]',
      previewContainer: '[data-js-element="product-personalisation-modal-preview-container"]',
      imageContainer: '[data-product-image-container]',
      secondaryVariationBtn: '[data-secondary-variation-button]',
      secondaryVariationRadio: '[data-secondary-variation-radio]',
      masterId: '[data-master-id]',
      dataButtonIndex: '[data-button-index]',
      screenReaderProgress: '[data-progress-sr]',
      screenReaderStepTarget: '.productPersonalisationModal_progressAlert',
      quantityInput: '[data-quantity-input]',
      productImage: '[data-product-image]',
      previewUnavailableMsg: '[data-js-element="product-personalisation-preview-unavailable-message"]',
      remainingCharacters: '[data-remaining-characters]',
      scrollBtn: '[data-scroll-btn]',
      scrollableContainer: '.productPersonalisationModal_inner',
      variation: '.productVariations_radio',
      textInput: '[data-js-element="product-personalisation-input"]',
      designStepRemoved: '.productPersonalisationModal_designStepRemoved',
    };

    const _messages = {
      namePrefix: app.utils.getProperty('personalisationNamePrefix', 'productPersonalisationModal')
    }

    const _attributes = {
      progressIndex: 'data-progress-index',
      stepIndex: 'data-step-index'
    };

    const _subscribeChannels = {
      showModal: 'productPersonalisation/openModal',
      newImage: 'productMultipleImages/newImage',
      inputStateUpdated: 'productPersonalisation/inputStateUpdate',
      closeModal: 'ProductPersonalisationModal/closeModal',
      characterCountUpdated: 'productPersonalisation/characterCountUpdate',

    };

    const _publishChannels = {
      personalisationResetInput: 'productPersonalisation/resetInput',
    };

    const initialInputState = {
      isValid: true,
      displayable: true,
      data: ""
    };

    const _state = {
      currentStep: 1,
      maxSteps: 3,
      minSteps: 1,
      maxLineLength: 20,
      inputState: {
        message: initialInputState,
        name: initialInputState,
      },
      initialSelectedChildProduct: null,
      initialProductId: siteObj.productID
    };

    const _init = (element) => {
      component.element = element;
      component.elements = {
        closeModalBtn: element.querySelector(component.selectors.closeModalBtn),
        nextBtn: [...element.querySelectorAll(component.selectors.nextBtn)],
        previousBtn: [...element.querySelectorAll(component.selectors.previousBtn)],
        resetBtn: [...element.querySelectorAll(component.selectors.resetBtn)],
        progressBar: [...element.querySelectorAll(component.selectors.progressBar)],
        stepContent: [...element.querySelectorAll(component.selectors.stepContent)],
        designStep: element.querySelector(component.selectors.designStep),
        messagePreviewText: element.querySelector(component.selectors.messagePreviewText),
        namePreviewText: element.querySelector(component.selectors.namePreviewText),
        previewContainer: element.querySelector(component.selectors.previewContainer),
        imageContainer: element.querySelector(component.selectors.imageContainer),
        secondaryVariationBtn: [...element.querySelectorAll(component.selectors.secondaryVariationBtn)],
        secondaryVariationRadio: [...element.querySelectorAll(component.selectors.secondaryVariationRadio)],
        dataButtonIndex: [...element.querySelectorAll(component.selectors.dataButtonIndex)],
        screenReaderProgress: element.querySelector(component.selectors.screenReaderProgress),
        screenReaderStepTarget: element.querySelector(component.selectors.screenReaderStepTarget),
        quantityInput: element.querySelector(component.selectors.quantityInput),
        initialVariation: element.querySelector(`button[data-linked-product-id="${component.state.initialProductId}"]`),
        previewUnavailableMsg: element.querySelector(component.selectors.previewUnavailableMsg),
        remainingCharacters: element.querySelector(component.selectors.remainingCharacters),
        scrollBtn: element.querySelector(component.selectors.scrollBtn),
        textInput: element.querySelector(component.selectors.textInput),
        designStepRemoved: element.querySelector(component.selectors.designStepRemoved),
      };

      component.subscribe();
      component.bind();
    };

    const _bind = () => {
      component.elements.closeModalBtn.addEventListener('click', component.closeModal);
      component.elements.textInput.addEventListener('keyup', (event) => {
        component.state.inputState.message.data = event.target.value;
        component.handleInputStateUpdate(event);
      })
      component.elements.nextBtn.forEach((btn) => {
        btn.addEventListener('click', component.nextButtonHandler);
      });
      component.elements.previousBtn.forEach((btn) => {
        btn.addEventListener('click', component.previousButtonHandler);
      });
      component.elements.resetBtn.forEach((btn) => {
        btn.addEventListener('click', component.resetModal);
      });
      window.addEventListener("resize", () => {
        component.makeFit();
      });
      component.elements.secondaryVariationBtn.forEach((btn) => {
        btn.addEventListener('click', component.handleSecondaryVariation)
      });
      component.elements.scrollBtn.addEventListener('click', component.scrollToTop);
    };

    const _subscribe = () => {
      app.subscribe(component.subscribeChannels.showModal, component.showModal);
      app.subscribe(component.subscribeChannels.inputStateUpdated, (state) => component.handleInputStateUpdate(state));
      app.subscribe(component.subscribeChannels.closeModal, component.closeModal);
      app.subscribe(component.subscribeChannels.newImage, (data) => component.updateImage(data));
      app.subscribe(component.subscribeChannels.characterCountUpdated, (characters) => component.updateRemainingCharacters(characters));
    };

    const _setDefaultVariations = () => {
      if(document.querySelector(".productVariations_radio:checked")) {
        component.state.initialSelectedChildProduct = document.querySelector(".productVariations_radio:checked").value;
        component.elements.initialSizeOption = component.element.querySelector(`button.productPersonalisationModal_secondaryVariationBtn[data-option-id="${component.state.initialSelectedChildProduct}"]`);
      } else {
        component.state.initialSelectedChildProduct = component.element.querySelectorAll(".productVariations_radio")[0].value;
        component.elements.initialSizeOption = component.element.querySelector(`button.productPersonalisationModal_secondaryVariationBtn[data-option-id="${component.state.initialSelectedChildProduct}"]`);
        component.elements.initialSizeOption.click();
      }
    };

    const _showModal = () => {
      // component.setDefaultVariations();
      if(component.elements.designStep) {
        component.state.currentStep = 2
        component.state.minSteps = 2

      }
      component.element.classList.add(component.classes.showModal);
      component.accessibleModalHelper = new accessibleModalHelper(
        component.element,
        component.closeModal,
        component.elements.screenReaderStepTarget
      );
    };

    const _closeModal = () => {
      component.element.classList.remove(component.classes.showModal);
      component.accessibleModalHelper && component.accessibleModalHelper.close();
      component.resetModal();
    };

    const _resetModal = () => {
      app.publish(component.publishChannels.personalisationResetInput);
      component.navigateToStep(component.state.minSteps);
      // component.elements.initialSizeOption.click();
      component.elements.quantityInput.value = 1;
      component.elements.quantityInput.dispatchEvent(new Event('change'));
      // component.elements.initialVariation.click();
      if(component.elements.designStep) {
        component.elements.messagePreviewText.innerText = component.messages.namePrefix+ " ";
        component.state.inputState.message.data = "";
        component.elements.dataButtonIndex[0].setAttribute('disabled', '');
      }
    };

    const _updateImage = ({ productId }) => {
      component.checkSecondaryVariationIsDisabled();
      productId = productId || siteObj.productId;
      app.ajax.get({
        url: `/${productId}.image?imageSize=original`,
        success: component.updateImageSuccess,
        error: () => console.error(`Could not load product image. productId=${productId}`),
      });
    };

    const _updateImageSuccess = (result) => {
      const parsed = new DOMParser().parseFromString(result, "text/html");
      const imgUrl = parsed.querySelector('img').src;
      component.element.querySelector(component.selectors.productImage).src = imgUrl;
    };

    const _nextButtonHandler = (event) => {
      const currentIndex = component.state.currentStep;
      const isDisabled = event.target.hasAttribute('disabled');
      if (component.state.currentStep < component.state.maxSteps && !isDisabled) component.navigateToStep(currentIndex + 1);
    };

    const _previousButtonHandler = () => {
      const currentIndex = component.state.currentStep;
      if (component.state.currentStep > component.state.minSteps) component.navigateToStep(currentIndex - 1);
    };

    const _navigateToStep = (stepIndex) => {
      component.state.currentStep = stepIndex;
      component.updateProgressBar();
      component.updateStepContent();
      component.elements.screenReaderProgress.innerHTML = stepIndex;
    };

    const _updateProgressBar = () => {
      component.elements.progressBar.forEach(element => {
        element.classList.remove("active");
        if (element.getAttribute(component.attributes.progressIndex) <= component.state.currentStep) {
          element.classList.add("active");
        };
      });
    };

    const _updateStepContent = () => {
      component.elements.stepContent.forEach(element => {
        element.classList.remove("show");
        if (element.getAttribute(component.attributes.stepIndex) == component.state.currentStep) {
          element.classList.add("show");
        };
      });
      component.accessibleModalHelper.reload(component.element, component.elements.screenReaderStepTarget);
    };

    const _handleInputStateUpdate = (newState) => {
      component.state.inputState[newState.inputIdentifier] = newState.state;
      if (component.state.inputState.message.displayable && component.state.inputState.name.displayable) {

        const phrase = component.state.inputState.message.data;
        const name = component.state.inputState.name.data;

        if(component.state.inputState.message.data !== '' || component.state.inputState.name.data !== ''){
          component.elements.dataButtonIndex[0].removeAttribute('disabled');
        } else {
          component.elements.dataButtonIndex[0].setAttribute('disabled', '');
        }

        if(component.elements.designStepRemoved){
          component.setMessage(component.messages.namePrefix+ " " + phrase);
        } else {
          component.setMessage(phrase + " " + name);
        }
        component.makeFit();
      } else if((!component.state.inputState.message.displayable || !component.state.inputState.name.displayable) && (component.state.inputState.message.isValid && component.state.inputState.name.isValid)) {

        component.elements.dataButtonIndex[0].removeAttribute('disabled');
        const unavailableMsg = component.elements.previewUnavailableMsg.innerHTML;

        component.setMessage(unavailableMsg);
        component.makeFit();
      } else {
        component.elements.dataButtonIndex[0].setAttribute('disabled', '');
        component.setMessage("");
      }
    };

    const _setMessage = (concatMessage) => {
      const trimmedString = concatMessage.substr(0, component.state.maxLineLength);

      let line1 = trimmedString;
      let line2 = "";

      if(concatMessage.length > component.state.maxLineLength) {
        line1 = trimmedString.substr(0, trimmedString.lastIndexOf(" "));
        line2 = concatMessage.substr(line1.length, concatMessage.length);
      }

      component.elements.messagePreviewText.innerText = line1;
      component.elements.namePreviewText.innerText = line2;
    };

    const _makeFit = () => {
      const defaultFontSize = component.calculateFontSize(component.elements.previewContainer);
      const previewContainerWidth = component.elements.previewContainer.clientWidth;

      const { width:messageWidth, overflow:messageOverflow } = component.calcOverflow(component.elements.messagePreviewText);
      const {width:nameWidth, overflow:nameOverflow} = component.calcOverflow(component.elements.namePreviewText);

      if(messageOverflow < 0 || nameOverflow < 0) {
        let newFontSize;
        if(messageOverflow <= nameOverflow){
          newFontSize = (previewContainerWidth / messageWidth) * defaultFontSize;
        } else {
          newFontSize = (previewContainerWidth / nameWidth) * defaultFontSize;
        }
        component.elements.messagePreviewText.style.fontSize = `${newFontSize}px`;
        component.elements.namePreviewText.style.fontSize = `${newFontSize}px`;
      } else {
        component.elements.messagePreviewText.style.fontSize = `${defaultFontSize}px`;
        component.elements.namePreviewText.style.fontSize = `${defaultFontSize}px`;
      }
    };

    const _calcOverflow = (el) => {
      const textWidth = component.calcTextWidth(el);
      const parentWidth = component.elements.previewContainer.getBoundingClientRect().width;
      return { width: textWidth, overflow: parentWidth - textWidth };
    };

    const _calculateFontSize = (el) => {
      return parseInt(getComputedStyle(el).getPropertyValue('font-size').split("px")[0]);
    };

    const _calcTextWidth = (el) => {
      const fontSize = component.calculateFontSize(component.elements.previewContainer);
      const wordStyle = getComputedStyle(component.elements.messagePreviewText);
      const fontStyle = wordStyle.getPropertyValue('font-style');
      const fontVariant = wordStyle.getPropertyValue('font-variant');
      const fontWeight = wordStyle.getPropertyValue('font-weight');
      const fontFamily = wordStyle.getPropertyValue('font-family');

      // to allow space for the next widest possible character
      const text = el.innerText.concat('M');

      const font = (fontStyle + ' ' + fontVariant + ' ' + fontWeight + ' ' + `${fontSize}px` + ' ' + fontFamily).replace(/ +/g, ' ').trim();
      const canvas = component.calcTextWidth.canvas || (component.calcTextWidth.canvas = document.createElement("canvas"));
      const context = canvas.getContext("2d");
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    };

    const _checkSecondaryVariationIsDisabled = () => {
      component.elements.secondaryVariationRadio.forEach(radio => {
        const optionId = radio.value;
        const variationBtn = document.querySelector(`button[data-variation-unique-id="rb-${optionId}"]`);
        radio.nextElementSibling.innerHTML = variationBtn.innerHTML;
        radio.nextElementSibling.classList.remove(radio.nextElementSibling.classList[radio.nextElementSibling.classList.length-1]);
        radio.nextElementSibling.classList.add(variationBtn.classList[variationBtn.classList.length-1]);
        if(variationBtn.disabled){
          radio.disabled = true;
          radio.nextElementSibling.disabled = true;
        }
      });
    };

    const _handleSecondaryVariation = (e) => {
      component.elements.secondaryVariationRadio.forEach(radio => radio.checked = false);
      const radioBtn = e.target.previousElementSibling;
      const optionId = radioBtn.value;
      radioBtn.checked = true;
      const variationBtn = document.querySelector(`button[data-variation-unique-id="rb-${optionId}"]`);
      variationBtn.click();
    };

    const _updateRemainingCharacters = (characters) => {
      component.elements.remainingCharacters.innerHTML = characters.toString();
    };

    const _scrollToTop = () => {
      component.element.querySelector(component.selectors.scrollableContainer).scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    component.init = _init;
    component.subscribe = _subscribe;
    component.bind = _bind;
    component.classes = _classes;
    component.selectors = _selectors;
    component.messages = _messages;
    component.attributes = _attributes;
    component.state = _state;
    component.subscribeChannels = _subscribeChannels;
    component.publishChannels = _publishChannels;
    component.showModal = _showModal;
    component.closeModal = _closeModal;
    component.nextButtonHandler = _nextButtonHandler;
    component.previousButtonHandler = _previousButtonHandler;
    component.resetModal = _resetModal;
    component.navigateToStep = _navigateToStep;
    component.updateProgressBar = _updateProgressBar;
    component.updateStepContent = _updateStepContent;
    component.handleInputStateUpdate = _handleInputStateUpdate;
    component.updateImage = _updateImage;
    component.updateImageSuccess = _updateImageSuccess;
    component.makeFit = _makeFit;
    component.calcOverflow = _calcOverflow;
    component.calcTextWidth = _calcTextWidth;
    component.handleSecondaryVariation = _handleSecondaryVariation;
    component.checkSecondaryVariationIsDisabled = _checkSecondaryVariationIsDisabled;
    component.calculateFontSize = _calculateFontSize;
    component.setMessage = _setMessage;
    component.updateRemainingCharacters = _updateRemainingCharacters;
    component.scrollToTop = _scrollToTop;
    component.setDefaultVariations = _setDefaultVariations;

    return component;
  };
  return ProductPersonalisationModal;
});
